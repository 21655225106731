import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem, Button, makeStyles } from '@material-ui/core';
import tick from 'src/assets/images/tick.svg';

const useStyles = makeStyles(theme => ({
  buttonLeaf: {
    color: theme.palette.text.secondary,
    justifyContent: 'space-Between',
    paddingRight: theme.spacing(3),
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 400
  },
  active: {
    backgroundColor: theme.palette2.background.brandTint1
  },
  title: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    color: theme.palette2.background.primary,
    paddingLeft: theme.spacing(7.5),
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    textDecoration: 'none'
  },
  inactiveTitle: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    color: theme.palette2.text.secondary,
    paddingLeft: theme.spacing(7.5),
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    textDecoration: 'none'
  }
}));

const NavItem = ({ href, title }) => {
  const classes = useStyles();
  const location = useLocation();
  const locationName = location.pathname;

  return (
    <ListItem disableGutters={true}>
      <Button
        activeClassName={classes.active}
        className={classes.buttonLeaf}
        component={RouterLink}
        exact
        to={href}
      >
        {title && (
          <span
            className={
              locationName === href ? classes.title : classes.inactiveTitle
            }
          >
            {title}
          </span>
        )}
        {locationName === href && <img src={tick} alt="Profileicon" />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default NavItem;
