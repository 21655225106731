import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  Box,
  List,
  ListItem,
  Collapse,
  ButtonBase
} from '@material-ui/core';
import Text from 'src/components/Text';
import ChevronDown from 'src/assets/images/chevron-down.svg';
import ChevronUp from 'src/assets/images/chevron-up.svg';
import RecipesList from './RecipesList';

const MobileHeader = ({ activePath }) => {
  const [isRecipesOpen, setRecipesOpen] = useState(false);
  return (
    <List>
      <ListItem>
        <Link component={RouterLink} to="/" underline="none">
          <Text className={`${activePath.includes('home') ? 'primary' : ''}`}>
            Home
          </Text>
        </Link>
      </ListItem>
      <ListItem
        width="100%"
        component={ButtonBase}
        onClick={() => {
          setRecipesOpen(prev => !prev);
        }}
      >
        <Text
          className={`${
            activePath.includes('categories') || isRecipesOpen ? 'primary' : ''
          }`}
        >
          Recipes
        </Text>
        <Box ml="auto">
          <img alt="Logo" src={isRecipesOpen ? ChevronUp : ChevronDown} />
        </Box>
      </ListItem>
      <Collapse in={isRecipesOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem sx={{ pl: 4 }}>
            <RecipesList />
          </ListItem>
        </List>
      </Collapse>
      <ListItem>
        <Link href="https://shop.cookdtv.com" underline="none" target="_blank">
          <Text>Shop</Text>
        </Link>
      </ListItem>
  
      <ListItem>
        <Link href="https://shop.cookdtv.com/blogs/blog" underline="none" target="_blank">
          <Text>Blog</Text>
        </Link>
      </ListItem>
    </List>
  );
};

export default MobileHeader;
