import React, { useState, useRef, useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Link,
  ButtonBase,
  Hidden
} from '@material-ui/core';

import Text from 'src/components/Text';
import AppIconButton from 'src/components/AppIconButton';
import TopBarItem from './TopBarItem';
import Search from './Search';
// import Notifications from './Notifications';
// import Cart from './Cart';
import RecipesList from './RecipesList';
// import StoreList from './StoreList';
import Account from './Account';
import Logo from 'src/assets/images/logo.svg';
import ChevronDown from 'src/assets/images/chevron-down.svg';
import ChevronDownActive from 'src/assets/images/chevron-down-active.svg';
import MenuOpen from 'src/assets/images/hamburger-menu.svg';
import MenuClose from 'src/assets/images/menu-close.svg';
import Signin from './Signin';
import MobileHeader from './MobileHeader';
import MobileAccountMenu from './MobileAccountMenu';
import useAuth from 'src/hooks/useAuth';
import { useDispatch } from 'src/store';
import {
  getCuisines,
  getMeals,
  getMealCourses,
  getFilters
} from 'src/slices/recipes';

const useStyles = makeStyles(theme => {
  return {
    root: {
      zIndex: theme.zIndex.drawer + 100,
      backgroundColor: theme.palette2.background.paper,
      boxShadow: 'none'
    },
    toolbar: {
      minHeight: 64,
      backgroundColor: theme.palette2.background.paper,
      paddingLeft: 16,
      paddingRight: 16,
      borderBottom: `1px solid ${theme.palette2.divider}`,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 50,
        paddingRight: 50
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 150,
        paddingRight: 144
      }
    },
    popover: {
      width: '100%',
      maxWidth: '100%',
      left: `0 !important`
    }
  };
});

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { isAuthenticated, isNewUser } = useAuth();
  const [menuOpen, setMenuOpen] = useState(true);
  const [accountMenuOpen, setAccountMenuOpen] = useState(true);

  const [isRecipesOpen, setRecipesOpen] = useState(false);
  // const [isStoreOpen, setStoreOpen] = useState(false);
  const ref = useRef(null);
  const location = useLocation();

  const dispatch = useDispatch();

  const renderSignin = () => {
    if (!isAuthenticated || isNewUser) return <Signin />;
  };

  const renderAccount = () => {
    if (isAuthenticated && !isNewUser)
      return <Account handleAccountMenu={handleAccountMenu} />;
  };

  const handleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const handleAccountMenu = () => {
    setAccountMenuOpen(prev => !prev);
  };

  useEffect(() => {
    dispatch(getCuisines());
    dispatch(getMeals());
    dispatch(getMealCourses());
    dispatch(getFilters());
  }, [dispatch]);

  useEffect(() => {
    setMenuOpen(false);
    setAccountMenuOpen(false);
  }, [location.pathname]);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar mx={5} className={classes.toolbar} ref={ref}>
        <Hidden mdUp>
          <AppIconButton
            src={menuOpen ? MenuClose : MenuOpen}
            onClick={handleMenu}
          />
        </Hidden>
        <Box
          mr={{ sm: 0.5, md: 0, lg: 1.5 }}
          onMouseOver={() => {
            setRecipesOpen(false);
          }}
        >
          <Link component={RouterLink} to="/" underline="none">
            <img alt="Logo" src={Logo} width="100" height="30" />
          </Link>
        </Box>
        <Hidden smDown>
          <TopBarItem
            onMouseOver={() => {
              setRecipesOpen(false);
            }}
          >
            <Link component={RouterLink} to="/" underline="none">
              <Text
                className={`${
                  location.pathname.includes('home') ? 'primary' : ''
                }`}
              >
                Home
              </Text>
            </Link>
          </TopBarItem>
          <TopBarItem
            onMouseOver={() => {
              setRecipesOpen(true);
            }}
            onClick={() => {
              setRecipesOpen(false);
            }}
          >
            <Link component={RouterLink} to="/categories" underline="none">
              <Box display="flex" alignItems="center" component={ButtonBase}>
                <Text
                  className={`${
                    location.pathname.includes('categories') || isRecipesOpen
                      ? 'primary'
                      : ''
                  }`}
                >
                  Recipes
                </Text>
                <img
                  alt="Logo"
                  src={
                    location.pathname.includes('categories') || isRecipesOpen
                      ? ChevronDownActive
                      : ChevronDown
                  }
                />
              </Box>
            </Link>
          </TopBarItem>
          <TopBarItem>
            <Link
              href="https://shop.cookdtv.com"
              underline="none"
              target="_blank"
              onMouseOver={() => {
                setRecipesOpen(false);
              }}
            >
              <Text>Shop</Text>
            </Link>
          </TopBarItem>

          <TopBarItem>
            <Link
              href="https://shop.cookdtv.com/blogs/blog"
              underline="none"
              target="_blank"
              onMouseOver={() => {
                setRecipesOpen(false);
              }}
            >
              <Text>Blog</Text>
            </Link>
          </TopBarItem>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Search />
        {/* <Notifications />
        <Cart /> */}
        <Box ml={{ sm: 1, md: 0 }}>
          {renderSignin()}
          {renderAccount()}
        </Box>
      </Toolbar>

      {isRecipesOpen && (
        <Hidden smDown>
          <RecipesList
            onClose={() => {
              setRecipesOpen(false);
            }}
          />
        </Hidden>
      )}
      {menuOpen && (
        <Hidden mdUp>
          <MobileHeader activePath={location.pathname} />
        </Hidden>
      )}
      {accountMenuOpen && (
        <Hidden mdUp>
          <MobileAccountMenu activePath={location.pathname} />
        </Hidden>
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
