import { BaseService } from './BaseService';

export class AuthService extends BaseService { 
  async sendOtp(body, param) {
    const userDetails = await window.AwsWafIntegration.fetch("/api/v1/users", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });
    return userDetails.json();
  }
  async verifyOtp(body, param) {
    return this.post('/api/v1/users/verify', body, param);
  }
  async resendOtp(body, param) {
    return this.put('/api/v1/users/resend_otp', body, param);
  }
  async logout(id) {
    return this.remove(`/api/v1/users/${id}/logout`);
  }
  async handleOTPlessLogin(body, param) {
    return this.post('/api/v1/users/otpless', body, param);
  }
}

export default new AuthService();
