import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: 4,
      [theme.breakpoints.up('md')]: {
        padding: 8
      },
      [theme.breakpoints.up('lg')]: {
        padding: 12
      }
    }
  };
});
const AppIconButton = props => {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.root}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <img alt={props.alt} src={props.src} />
    </IconButton>
  );
};

export default AppIconButton;
