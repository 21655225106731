import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  Box,
  List,
  ListItem,
  Collapse,
  ButtonBase
} from '@material-ui/core';
import Text from 'src/components/Text';
import ChevronDown from 'src/assets/images/chevron-down.svg';
import ChevronUp from 'src/assets/images/chevron-up.svg';
import profileicon from 'src/assets/images/profile-icon.svg';
import settings from 'src/assets/images/settings.svg';
import logouts from 'src/assets/images/logout.svg';
import useAuth from 'src/hooks/useAuth';

const MobileAccountMenu = ({ activePath }) => {
  const { logout } = useAuth();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const handleLogout = () => {
    logout();
  };
  return (
    <List>
      <ListItem>
        <Link component={RouterLink} to="/profile" underline="none">
          <Box display="flex">
            <Box pr={1}>
              <img src={profileicon} alt="Profileicon" />
            </Box>
            <Text>Profile</Text>
          </Box>
        </Link>
      </ListItem>
      <ListItem
        width="100%"
        component={ButtonBase}
        onClick={() => {
          setIsSettingsOpen(prev => !prev);
        }}
      >
        <Box display="flex">
          <Box pr={1}>
            <img src={settings} alt="settings" />
          </Box>
          <Text>My Settings</Text>
        </Box>
        <Box ml="auto">
          <img alt="Logo" src={isSettingsOpen ? ChevronUp : ChevronDown} />
        </Box>
      </ListItem>
      <Collapse in={isSettingsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <Box pl={4}>
              <Link
                component={RouterLink}
                to="/profile/preferences"
                underline="none"
              >
                <Text className={`subtext`}>Preferences</Text>
              </Link>
            </Box>
          </ListItem>
          <ListItem>
            <Box pl={4}>
              <Link
                component={RouterLink}
                to="/profile/recent-recipes"
                underline="none"
              >
                <Text className={`subtext`}>My recently viewed recipes</Text>
              </Link>
            </Box>
          </ListItem>
          <ListItem>
            <Box pl={4}>
              <Link
                component={RouterLink}
                to="/profile/ratings"
                underline="none"
              >
                <Text className={`subtext`}>My ratings & tips</Text>
              </Link>
            </Box>
          </ListItem>
        </List>
      </Collapse>
      <ListItem onClick={handleLogout}>
        <Box display="flex">
          <Box pr={1}>
            <img src={logouts} alt="logouts" />
          </Box>
          <Text>Logout</Text>
        </Box>
      </ListItem>
    </List>
  );
};

export default MobileAccountMenu;
