import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Button,
  List,
  makeStyles
} from '@material-ui/core';
import profileicon from 'src/assets/images/profile-icon.svg';
import settings from 'src/assets/images/settings.svg';
// import info from 'src/assets/images/info (1).svg';
// import logouts from 'src/assets/images/logout.svg';
import tick from 'src/assets/images/tick.svg';
import NavItem from './NavItem';

const sections = [
  {
    subheader: 'PROFILE',
    image: profileicon,
    href: '/profile',
    items: []
  },
  {
    subheader: 'MY SETTINGS',
    image: settings,
    href: '#',
    items: [
      {
        title: 'Preferences',
        href: '/profile/preferences'
      },
      {
        title: 'My recently viewed recipes',
        href: '/profile/recent-recipes'
      },
      {
        title: 'My ratings & tips',
        href: '/profile/ratings'
      }
      // {
      //   title: 'My orders',
      //   href: '/profile3'
      // },
      // {
      //   title: 'Manage Addresses',
      //   href: '/profile5'
      // }
    ]
  }
  // {
  //   subheader: 'ABOUT COOKD',
  //   image: info,
  //   href: '#',
  //   items: [
  //     {
  //       title: 'Terms & Condition',
  //       href: '/profile/terms'
  //     }
  //     // {
  //     //   title: 'Get help',
  //     //   href: '/profile4'
  //     // }
  //   ]
  // }
  // {
  //   subheader: 'LOGOUT',
  //   image: logouts,
  //   href: '#',
  //   items: []
  // }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding={true}>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        href={item.href}
        image={item.image}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette2.background.profile,
    position: 'relative'
  },
  outerBox: {
    backgroundColor: theme.palette2.background.paper,
    borderRadius: 4,
    height: '100vh'
  },
  popover: {
    paddingLeft: theme.spacing(3.75),
    paddingTop: theme.spacing(3),
    borderTopWidth: 1,
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette2.background.brandTint1
  },
  popoverinActive: {
    paddingLeft: theme.spacing(3.75),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  selectHeader: {
    width: '100%',
    justifyContent: 'space-Between',
    paddingRight: theme.spacing(3),
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette2.background.primary
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette2.text.secondary
  },
  drawer: {
    width: 362,
    position: 'relative',
    height: 'calc(100% - 64px)'
  },
  image: {
    paddingRight: theme.spacing(1)
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, openMobile, onMobileClose]);

  const content = (
    <Box className={classes.box}>
      <Box m={2} className={classes.outerBox}>
        <Box display="flex" flexDirection="column">
          {sections.map(section => (
            <Box key={section.subheader}>
              {' '}
              <List
                disablePadding={true}
                subheader={
                  <Box
                    display="flex"
                    className={
                      section.href === location.pathname &&
                      section.href === '/profile'
                        ? classes.popover
                        : classes.popoverinActive
                    }
                  >
                    <img
                      alt="icon"
                      className={classes.image}
                      src={section.image}
                    />
                    <Button
                      className={
                        section.href === location.pathname &&
                        section.href === '/profile'
                          ? classes.selectHeader
                          : ''
                      }
                      component={RouterLink}
                      exact="true"
                      to={section.href}
                    >
                      <span
                        className={
                          section.href === location.pathname &&
                          section.href === '/profile'
                            ? classes.selectHeader
                            : classes.title
                        }
                      >
                        {section.subheader}
                      </span>
                      {section.href === location.pathname &&
                        section.href === '/profile' && (
                          <img src={tick} alt="Profileicon" />
                        )}
                    </Button>
                  </Box>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
              <Divider light />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          PaperProps={{ style: { border: 'none' } }}
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          PaperProps={{ style: { border: 'none' } }}
          classes={{ paper: classes.drawer }}
          open={!openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
