import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    root: {
      '&:disabled': {
        color: theme.palette2.text.paper,
        opacity: 0.5
      },
      fontSize: 12,
      [theme.breakpoints.up('md')]: {
        fontSize: 14
      },
      '&.primary': {
        backgroundColor: theme.palette2.background.primary,
        color: theme.palette2.text.paper,
        '&:hover': {
          backgroundColor: theme.palette2.background.primary
        }
      },
      '&.secondary': {
        backgroundColor: theme.palette2.background.secondary,
        color: theme.palette2.text.paper,
        '&:hover': {
          backgroundColor: theme.palette2.background.secondary
        }
      },
      '&.secondary-outlined': {
        backgroundColor: theme.palette2.text.paper,
        color: theme.palette2.background.secondary,
        border: `1px solid ${theme.palette2.background.secondary}`,
        '&:hover': {
          backgroundColor: theme.palette2.text.paper
        }
      }
    }
  };
});

const AppButton = ({
  className = '',
  children,
  disabled,
  disableElevation,
  disableFocusRipple,
  disableRipple,
  href,
  size,
  endIcon,
  fullWidth,
  startIcon,
  variant,
  sx,
  onClick,
  color,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.root, className)}
      disabled={disabled}
      endIcon={endIcon}
      fullWidth={fullWidth}
      startIcon={startIcon}
      variant={variant}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      href={href}
      size={size}
      sx={sx}
      onClick={onClick}
      color={color}
      {...rest}
    >
      {children}
    </Button>
  );
};

AppButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  disableElevation: PropTypes.bool,
  disableFocusRipple: PropTypes.bool,
  disableRipple: PropTypes.bool,
  href: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sx: PropTypes.object,
  onClick: PropTypes.func,
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning'
  ])
};

AppButton.defaultProps = {
  disabled: false,
  fullWidth: true,
  variant: 'text',
  disableElevation: false,
  disableFocusRipple: false,
  disableRipple: false,
  size: 'medium'
};

export default AppButton;
