import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, List, ListItem, makeStyles } from '@material-ui/core';

import Text from 'src/components/Text';
import VeggiesBg from 'src/assets/images/veggies.svg';
import DATA from './RecipeData';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette2.divider}`,
    width: '100%',
    backgroundImage: `url(${VeggiesBg})`,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      fontSize: 16
    }
  },
  content: {
    fontSize: 11,
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      fontSize: 14
    }
  },
  list: {
    padding: 0
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75)
    }
  }
}));

const RecipesList = ({ onClose }) => {
  const classes = useStyles();
  return (
    <Box
      px={{ sm: 4, md: 6, lg: 8 }}
      py={{ sm: 2, md: 2.5, lg: 3 }}
      className={classes.root}
      onMouseLeave={() => {
        onClose();
      }}
    >
      <Grid sx={{ flexGrow: 1 }} container>
        {DATA.map((datum, index) => (
          <Grid item xs={6} md={2} key={index}>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.title}>{datum.title}</Text>
              </ListItem>
              {datum.data.map(item => (
                <ListItem
                  className={classes.listItem}
                  component={RouterLink}
                  to={{
                    pathname: `/categories/${datum.field}/${item.name}`,
                    state: {
                      currentPage: 'Categories page',
                      currentSection: datum.title,
                    }
                  }}
                  onClick={onClose}
                  key={item.id}
                >
                  <Text className={`${classes.content} subtext`}>
                    {item.name}
                  </Text>
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RecipesList;
