import { BaseService } from './BaseService';

export class RecipeService extends BaseService {
  async getFilters(param) {
    return this.get('/api/v1/filters', param);
  }
  async getCuisines(param) {
    return this.get('/api/v1/cuisines', param);
  }
  async getMeals(param) {
    return this.get('/api/v1/meal_times', param);
  }
  async getMealCourses(param) {
    return this.get('/api/v1/meal_courses', param);
  }
  async searchRecipes(param) {
    return this.get(`/api/v1/recipes/search`, param);
  }
  async searchRecipesByIngredients(param) {
    return this.get(`/api/v1/ingredients/recipes`, param);
  }
  async getPrimaryIngredients() {
    return this.get(`/api/v1/ingredients/primary_ingredients`);
  }
  async getIngredientsByQuery(param) {
    return this.get(`/api/v1/ingredients/search`, param);
  }
  async getRecipeById(id) {
    return this.get(`/api/v1/recipes/${id}`);
  }
  async likeDislikeRecipe(recipeId, param) {
    return this.post(`api/v1/recipes/${recipeId}/admire`, param);
  }
  async getRecipeActivities(recipeId) {
    return this.get(`api/v1/recipes/${recipeId}/user_activity`);
  }
  async updateRecipeMadeStatus(recipeId, param) {
    return this.post(`api/v1/recipes/${recipeId}/completed`, param);
  }
  async getRelatedRecipes(recipeId) {
    return this.get(`api/v1/recipes/${recipeId}/related_recipes`);
  }
  async getRecipeTips(recipeId, param) {
    return this.get(`api/v1/recipes/${recipeId}/tips`, param);
  }
  async getRecipeIngredients(recipeId) {
    return this.get(`api/v1/recipes/${recipeId}/recipes_ingredients`);
  }
  async getCookingInstructions(recipeId) {
    return this.get(`api/v1/recipes/${recipeId}/cooking_instructions`);
  }
  async createTip(recipeId, param) {
    return this.post(`api/v1/recipes/${recipeId}/tips`, param);
  }
  async reactToTip(recipeId, tipId, param) {
    return this.post(
      `api/v1/recipes/${recipeId}/tips/${tipId}/react_to_tip`,
      param
    );
  }
}

export default new RecipeService();
