import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    padding: `${theme.spacing(5)}px ${theme.spacing(8)}px`,
    backgroundColor: '#02091E',
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px`
    }
  },
  content: {
    height: '100%'
  },
  title: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette2.text.paper
  }
}));

const CookingLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default CookingLayout;
