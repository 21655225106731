import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RecipeService } from 'src/services/RecipeService';

export const getFilters = createAsyncThunk('recipe/getFilters', async param => {
  return new RecipeService().getFilters(param);
});

export const getCuisines = createAsyncThunk(
  'recipe/getCuisines',
  async param => {
    return new RecipeService().getCuisines(param);
  }
);

export const getMeals = createAsyncThunk('recipe/getMeals', async param => {
  return new RecipeService().getMeals(param);
});

export const getMealCourses = createAsyncThunk(
  'recipe/getMealCourses',
  async param => {
    return new RecipeService().getMealCourses(param);
  }
);

export const searchRecipes = createAsyncThunk(
  'recipe/searchRecipes',
  async param => {
    return new RecipeService().searchRecipes(param);
  }
);

export const searchRecipesByIngredients = createAsyncThunk(
  'recipe/searchRecipesByIngredients',
  async param => {
    return new RecipeService().searchRecipesByIngredients(param);
  }
);

export const getRecipeById = createAsyncThunk(
  'recipe/getRecipeById',
  async param => {
    return new RecipeService().getRecipeById(param);
  }
);

export const getRecipeTips = createAsyncThunk(
  'recipe/getRecipeTips',
  async param => {
    return new RecipeService().getRecipeTips(param.recipeId, param.body);
  }
);

const slice = createSlice({
  name: 'recipe',
  initialState: {
    status: '',
    cuisines: [],
    meals: [],
    mealCourses: [],
    recipesByCategory: [],
    searchedRecipes: {
      metadata: {
        total_count: 0,
        page: 0,
        total_page: 0,
        per_page: 0
      },
      recipes: []
    },
    filters: {},
    recipeDetail: null,
    recipeTips: {
      metadata: {
        total_count: 0,
        page: 0,
        total_page: 0,
        per_page: 0
      },
      data: []
    }
  },
  extraReducers: {
    [getFilters.fulfilled]: (state, action) => {
      state.status = 'success';
      state.filters = action.payload;
    },
    [getCuisines.fulfilled]: (state, action) => {
      state.status = 'success';
      state.cuisines = action.payload;
    },
    [getMeals.fulfilled]: (state, action) => {
      state.status = 'success';
      state.meals = action.payload;
    },
    [getMealCourses.fulfilled]: (state, action) => {
      state.status = 'success';
      state.mealCourses = action.payload;
    },
    [searchRecipes.fulfilled]: (state, action) => {
      state.status = 'success';
      const { metadata, data } = action.payload;
      state.searchedRecipes.metadata = metadata;
      if (metadata.page === 1) {
        state.searchedRecipes.recipes = data;
      } else {
        state.searchedRecipes.recipes = [
          ...state.searchedRecipes.recipes,
          ...data
        ];
      }
    },
    [searchRecipesByIngredients.fulfilled]: (state, action) => {
      state.status = 'success';
      const { metadata, data } = action.payload;
      state.searchedRecipes.metadata = metadata;
      if (metadata.page === 1) {
        state.searchedRecipes.recipes = data;
      } else {
        state.searchedRecipes.recipes = [
          ...state.searchedRecipes.recipes,
          ...data
        ];
      }
    },
    [getRecipeById.fulfilled]: (state, action) => {
      state.status = 'success';
      state.recipeDetail = action.payload;
    },
    [getRecipeTips.fulfilled]: (state, action) => {
      state.status = 'success';
      const { metadata, data } = action.payload;
      state.recipeTips.metadata = metadata;
      if (metadata.page === 1) {
        state.recipeTips.data = data;
      } else {
        state.recipeTips.data = [...state.recipeTips.data, ...data];
      }
    }
  },
  reducers: {
    clearSearchedRecipes(state) {
      state.searchedRecipes = {
        metadata: {
          total_count: 0,
          page: 0,
          total_page: 0,
          per_page: 0
        },
        recipes: []
      };
    },
    clearRecipeDetail(state) {
      state.recipeDetail = null;
    }
  }
});

export const reducer = slice.reducer;
export const actions = slice.actions;

export default slice;
