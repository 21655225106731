import React, { useState, useEffect, useCallback, useRef } from 'react';
import { initOTPless } from './utils/initOtpless';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import AppButton from 'src/components/AppButton';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles(theme => ({
  root: {},
  btn: {
    textTransform: 'capitalize',
    padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px`
  },
  modalContainer: {
    display: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
const Signin = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const authCtx = useAuth();

  const handleOpen = () => {
    setOpen(true);
    initOTPless(handleOTPlessLogin);
  };
  const handleClose = () => {
    setOpen(false);
    authCtx.setNewUser(false);
  };
  const handleOTPlessLogin = otplessUser => {
    authCtx.handleOTPlessLogin(otplessUser.token);
  };
  return (
    <main>
      <AppButton className={clsx('primary', classes.btn)} onClick={handleOpen}>
        Sign in
      </AppButton>
      <div
        className={classes.modalContainer}
        id="modalContainer"
        onClick={handleClose}
        style={{ display: open ? 'flex' : 'none' }}
      >
        <div id="otpless-login-page"></div>
      </div>
    </main>
  );
};

export default Signin;
