import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppIconButton from 'src/components/AppIconButton';
import profile from 'src/assets/images/profile.svg';
import profileicon from 'src/assets/images/profile-icon.svg';
import settings from 'src/assets/images/settings.svg';
// import info from 'src/assets/images/info (1).svg';
import logouts from 'src/assets/images/logout.svg';
import useAuth from 'src/hooks/useAuth';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  makeStyles,
  Hidden
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 147
  },
  menu: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  menuItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5)
  }
}));

const Account = ({ handleAccountMenu }) => {
  const { logout } = useAuth();
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    handleAccountMenu();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <Box mx={0.5} display="flex" alignItems="center" ref={ref}>
        <AppIconButton
          src={profile}
          alt="Profile"
          onClick={handleOpen}
        ></AppIconButton>
      </Box>
      <Hidden smDown>
        <Menu
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
          className={classes.menu}
        >
          <MenuItem
            className={classes.menuItem}
            component={RouterLink}
            to="/profile"
          >
            <Box display="flex">
              <Box pr={1}>
                <img src={profileicon} alt="Profileicon" />
              </Box>
              <Typography>Profile</Typography>
            </Box>
          </MenuItem>
          <MenuItem component={RouterLink} to="#">
            <Box display="flex">
              <Box pr={1}>
                <img src={settings} alt="settings" />
              </Box>
              <Typography>My Settings</Typography>
            </Box>
          </MenuItem>
          {/* <MenuItem component={RouterLink} to="#">
            <Box display="flex">
              <Box pr={1}>
                <img src={info} alt="about" />
              </Box>
              <Typography>About cookd</Typography>
            </Box>
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            <Box display="flex">
              <Box pr={1}>
                <img src={logouts} alt="logouts" />
              </Box>
              <Typography>Logout</Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Hidden>
    </>
  );
};

export default Account;
