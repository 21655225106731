import React from 'react';
import { Helmet } from 'react-helmet';

// const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const GA_MEASUREMENT_ID_2 = 'GTM-K9L8WLW'

const GoogleAnalytics = () => {
  return (
    <Helmet>
      {/* <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script> */}
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GA_MEASUREMENT_ID_2}');`}
      </script>

      <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=${GA_MEASUREMENT_ID_2}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
      </noscript>
    </Helmet>
  );
};

export default GoogleAnalytics;
