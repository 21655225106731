import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette2.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      fontSize: 16
    },
    '&.primary': {
      color: theme.palette2.text.primary
    },
    '&.subtext': {
      color: theme.palette2.text.subtext
    },
    '&.white': {
      color: theme.palette2.text.paper
    }
  }
}));

const Text = ({ className = '', children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.root, className)} {...rest}>
        {children}
    </Typography>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Text;
