import axios from 'axios';

export const client = axios.create({
  baseURL: 'https://cookdtv.com',
  headers: {
    'Content-Type': 'application/json'
  }
});

client.interceptors.response.use(
  function(response) {
    return response.data;
  },
  function(error) {
    return Promise.reject(error);
  }
);
