import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  makeStyles,
  ButtonBase
} from '@material-ui/core';
import ListDividers from '@material-ui/core/Divider';
import Text from 'src/components/Text';

const useStyles = makeStyles(theme => {
  return {
    root: {
      border: '1px solid #C2D1D9'
    },
    container: {
      backgroundColor: theme.palette2.background.footer
    },
    heading: {
      color: theme.palette2.text.secondary,
      fontWeight: 500,
      fontSize: 14
    },
    item: {
      color: theme.palette2.text.placeholder,
      fontSize: 14,
      marginRight: 16,
      fontWeight: 'normal'
    },
    items: {
      color: theme.palette2.text.secondary,
      fontSize: 14,
      fontWeight: 400
    },
    list: {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(4)
        // paddingLeft: theme.spacing(11)
      },
      '&.newsletter': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: `${theme.spacing(0)} !important`
        }
      }
    },
    listItem: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: 0,
      paddingRight: 0
    },
    button: {
      borderRadius: 5,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      fontSize: 12,
      backgroundColor: theme.palette2.text.secondary,
      color: theme.palette2.text.paper,
      lineHeight: '20px',
      padding: `${theme.spacing(1.25)} ${theme.spacing(3)}`
    },
    input: {
      border: '1px solid #C2D1D9',
      borderRight: 0,
      borderRadius: 5,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      lineHeight: '28px',
      fontSize: 14,
      textIndent: 9,
      height: 40,
      flex: 'auto',
      '&:placeholder': {
        color: '#C2C2C2',
        fontSize: 14
      }
    }
  };
});

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <Box
        className={classes.container}
        px={{ xs: 6, md: 12, lg: 18 }}
        py={{ xs: 4, md: 3 }}
      >
        <Grid container>
          {/* <Grid item xs={12} sm={6} md={2}>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.heading}>Product</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Store</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Shopping List</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Saved</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Cart</Text>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.heading}>About</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Our Team</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Careers</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Press</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Blog</Text>
              </ListItem>
            </List>
          </Grid> */}
          <Grid item xs={12} sm={6} md={2}>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.heading}>Connect</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={ButtonBase}
                target="_blank"
                href="https://www.facebook.com/cookdtv"
              >
                <Text className={classes.item}>Facebook</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>Twitter</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={ButtonBase}
                href="https://instagram.com/cookdtv"
                target="_blank"
              >
                <Text className={classes.item}>Instagram</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={ButtonBase}
                href="https://www.linkedin.com/company/cookdtv/"
                target="_blank"
              >
                <Text className={classes.item}>LinkedIn</Text>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <List className={classes.list}>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.heading}>Contact</Text>
              </ListItem>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.item}>support@cookdtv.com</Text>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Text className={classes.item}>
                  AWFIS Building, Rajiv Gandhi Salai, OMR, Kandanchavadi,
                  Chennai, Tamil Nadu 600096
                </Text>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={false} md={1}></Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <List className={clsx(classes.list, 'newsletter')}>
              <ListItem
                className={classes.listItem}
                component={RouterLink}
                to="#"
              >
                <Text className={classes.heading}>NewsLetter</Text>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Text className={classes.item}>
                  Join our Weekly Mailing List
                </Text>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Box display="flex" width="100%">
                  <input
                    className={classes.input}
                    type="email"
                    placeholder="Your Email"
                    onChange={() => {}}
                  ></input>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {}}
                  >
                    Subscribe
                  </Button>
                </Box>
              </ListItem>
            </List>
          </Grid> */}
        </Grid>
      </Box>
      <ListDividers />
      <Box className={classes.container} px={{ md: 12, lg: 18 }}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          py={{ md: 1 }}
        >
          <Box mx={{ xs: 'auto', md: 0 }} py={{ xs: 2, md: 0 }}>
            <ListItem
              className={classes.listItem}
              component={ButtonBase}
              target="_blank"
              href="https://cookdtv.com/terms"
            >
              <Text className={classes.items}>Terms and conditions</Text>
            </ListItem>
          </Box>
          {/* <ListDividers />
          <Box
            mx={{ xs: 'auto', md: 0 }}
            ml={{ md: 5 }}
            px={2}
            py={{ xs: 2, md: 0 }}
          >
            <Link component={RouterLink} to="#" underline="none">
              <Text className={classes.items}>Legal</Text>
            </Link>
          </Box> */}
          <ListDividers />
          <Box
            mx={{ xs: 'auto', md: 0 }}
            ml={{ md: 6, lg: 8 }}
            px={2}
            py={{ xs: 2, md: 0 }}
          >
            <ListItem
              className={classes.listItem}
              component={ButtonBase}
              target="_blank"
              href="https://cookdtv.com/privacy-policy"
            >
              <Text className={classes.items}>Privacy Policy</Text>
            </ListItem>
          </Box>
          <ListDividers />
          <Box
            mx={{ xs: 'auto', md: 0 }}
            ml={{ md: 'auto' }}
            py={{ xs: 2, md: 0 }}
          >
            <Link component={RouterLink} to="#" underline="none">
              <Text className={classes.items}>
                ©{new Date().getFullYear()} - Cookd | All Rights reserved
              </Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </footer>
  );
}
