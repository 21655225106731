/**
 * This file is created for demo purpose
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostsService } from 'src/services/PostsService';

export const getPosts = createAsyncThunk('posts/getPosts', async () => {
  return new PostsService().getAllPosts();
});
export const getPostById = createAsyncThunk('posts/getPostById', async id => {
  return new PostsService().getPostById(id);
});

const slice = createSlice({
  name: 'posts',
  initialState: {
    posts: [],
    status: null,
    post: null
  },
  extraReducers: {
    [getPosts.pending]: (state, action) => {
      state.status = 'pending';
    },
    [getPosts.fulfilled]: (state, { payload }) => {
      state.posts = payload;
      state.status = 'success';
    },
    [getPosts.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [getPostById.pending]: (state, action) => {
      state.status = 'pending';
    },
    [getPostById.fulfilled]: (state, { payload }) => {
      state.post = payload;
      state.status = 'success';
    },
    [getPostById.rejected]: (state, action) => {
      state.status = 'failed';
    }
  }
});

export const reducer = slice.reducer;

export default slice;
