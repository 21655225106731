import { BaseService } from './BaseService';

export class HomeService extends BaseService {
  async getFeed(param) {
    return this.get('/api/v1/home_feed', param);
  }

  async getCollectionByName(collectionName) {
    return this.get(`/api/v1/collections/${collectionName}`);
  }
}

export default new HomeService();
