import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';

import AppIconButton from 'src/components/AppIconButton';
import SearchImg from 'src/assets/images/search.svg';

const Search = () => {
  return (
    <Box mx={0.5} component={Link} to="/search">
      <AppIconButton src={SearchImg} alt="Search"></AppIconButton>
    </Box>
  );
};

export default Search;
