import { BaseService } from './BaseService';

export class ProfileService extends BaseService {
  async updateProfile(id, body, params) {
    return this.put(`/api/v1/users/${id}`, body, params);
  }
  async getProfile(id) {
    return this.get(`/api/v1/users/${id}`);
  }
  async getRecentlyViewedRecipes() {
    return this.get(`/api/v1/recipes/recent_views`);
  }
}

export default new ProfileService();
