import React from 'react';
import { Box } from '@material-ui/core';

const TopBarItem = ({ children, onMouseOver, onClick }) => {
  return (
    <Box
      mx={{ sm: 1, md: 2, lg: 2.5 }}
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default TopBarItem;
