import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/devias/components/SplashScreen';
import { client } from '../services/apiClient';
import AuthService from 'src/services/AuthService';
import ProfileService from 'src/services/ProfileService';

const initialAuthState = {
  isAuthenticated: false,
  isNewUser: false,
  isInitialized: false,
  user: null,
};


const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    client.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete client.defaults.headers.common.Authorization;
  }
};


const setId = id => {
  if (id) {
    localStorage.setItem('userId', id);
  } else {
    localStorage.removeItem('userId');
  }
};


const reducer = (state, action) => {
  switch (action.type) {

    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        isNewUser: false
      };
    }
    case 'HANDLE_OTPLESS_LOGIN': {
      const { user } = action.payload;
      return {
        ...state,
        user,
        token: user.auth_token,
        isAuthenticated: true
      };
    }
    
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'SET_NEW_USER': {
      const { isNewUser } = action.payload;
      return {
        ...state,
        isNewUser
      };
    }
   
    default: {
      return { ...state };
    }
  }
};


const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  handleOTPlessLogin: token => Promise.resolve(),
  setNewUser: isNewUser => {},
  logout: () => {},
  toggleSignInPopup: isOpen => {}
});


export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const handleOTPlessLogin = async accessToken => {
    setSession();
    setId();
    const response = await AuthService.handleOTPlessLogin({ token: accessToken });
    const { full_name ,auth_token, phone_number, id, new_record, confirmed} = response;
    dispatch({
      type: 'HANDLE_OTPLESS_LOGIN',
      payload: { 
        user:{
          full_name,
          phone_number: phone_number,
          confirmed,
          auth_token,
          new_record,
          id
        }
       },
       isAuthenticated: true
    });
    setSession(auth_token);
    setId(id);
  };
 
  
  const logout = () => {
    if (state.user && state.user.id) {
      AuthService.logout(state.user.id);
      dispatch({
        type: 'LOGOUT'
      });
      setSession();
      setId();
    }
  };

  const setNewUser = isNewUser => {
    dispatch({
      type: 'SET_NEW_USER',
      payload: { isNewUser }
    });
  };


  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const id = window.localStorage.getItem('userId');
        if (accessToken && id) {
          setSession(accessToken);
          setId(id);
          const response = await ProfileService.getProfile(id);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: response
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        handleOTPlessLogin,
        setNewUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
