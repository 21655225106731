import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import TopBar from '../AppLayout/TopBar';
import Footer from '../AppLayout/Footer';
import NavBar from './NavBar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(8),
    backgroundColor: theme.palette2.background.profile
  },
  content: {
    overflow: 'auto',
    width: '100%',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: `0px ${theme.spacing(1.12)}px`
    }
  }
}));

const ProfileLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div>
      <TopBar />
      <div className={classes.root}>
        <Box display="flex" gridColumnGap="4px" width="100%">
          <Box>
            <NavBar />
          </Box>
          <Box className={classes.content}>{children}</Box>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default ProfileLayout;
