const CUISINES1 = [
  {
    id: 1,
    name: 'Indian',
    image_url: 'http://cookdassets.imgix.net/10m97s3dqd57kbu225cg6vvf033e'
  },
  {
    id: 2,
    name: 'Chinese',
    image_url: 'http://cookdassets.imgix.net/nemtshodm3hrewy7ezikhmi2pn0s'
  },
  {
    id: 3,
    name: 'Asian',
    image_url: 'http://cookdassets.imgix.net/c7ymd74xfuroz023u6vdcbcj8pip'
  },
  {
    id: 4,
    name: 'Thai',
    image_url: 'http://cookdassets.imgix.net/m8p83ktwjg7vqn0v29joiw5lh7tu'
  },
  {
    id: 5,
    name: 'Japanese',
    image_url: 'http://cookdassets.imgix.net/twc4admwmxjna58acek1pzal78ce'
  },
  {
    id: 6,
    name: 'American',
    image_url: 'http://cookdassets.imgix.net/o6g1z5irf2b00mgva3qsgaz6ej7z'
  },
  {
    id: 7,
    name: 'Mexican',
    image_url: 'http://cookdassets.imgix.net/ygnmb254zdkz33j189h7qiqrub38'
  }
];

const CUISINES2 = [
  {
    id: 8,
    name: 'Italian',
    image_url: 'http://cookdassets.imgix.net/unmu3oqa63a1x03ilc6gulcafpgi'
  },
  {
    id: 9,
    name: 'French',
    image_url: 'http://cookdassets.imgix.net/y61yfq5rksugfm3k8jwifto1xwan'
  },
  {
    id: 10,
    name: 'Spanish',
    image_url: 'http://cookdassets.imgix.net/r392nmmfly4wskz2iuj7i988bbjf'
  },
  {
    id: 11,
    name: 'Continental',
    image_url: 'http://cookdassets.imgix.net/2qc9w0uvvyrgve5s78572no25sr5'
  },
  {
    id: 12,
    name: 'Middle Eastern',
    image_url: 'http://cookdassets.imgix.net/b7bwze0n3wvs8g5oxvh9q4b5bvpe'
  },
  {
    id: 13,
    name: 'Greek',
    image_url: null
  },
  {
    id: 14,
    name: 'Srilankan',
    image_url: null
  }
];

const MEAL_TIMES = [
  {
    id: 1,
    name: 'Breakfast',
    image_url: 'http://cookdassets.imgix.net/gi903cik0ez1udputonzo0fzbup7'
  },
  {
    id: 2,
    name: 'Brunch',
    image_url: 'http://cookdassets.imgix.net/jeos7ym2xu1y2x8i3w97gmb2ibv0'
  },
  {
    id: 3,
    name: 'Lunch',
    image_url: 'http://cookdassets.imgix.net/1d11c844cw1lutmcm6optyebgjyb'
  },
  {
    id: 4,
    name: 'Evening Snack',
    image_url: 'http://cookdassets.imgix.net/seqkct5ktpzjypepz2nytcpon5vo'
  },
  {
    id: 5,
    name: 'Dinner',
    image_url: 'http://cookdassets.imgix.net/eunezl4aq83gk1r5q3v6fqekdn81'
  },
  {
    id: 6,
    name: 'Late Night',
    image_url: 'http://cookdassets.imgix.net/sq7cvbtbfriaqdsxk9qe43ztlpzq'
  }
];

const MEAL_COURSES = [
  {
    id: 1,
    name: 'Soup',
    image_url: 'http://cookdassets.imgix.net/yr33bprinwwh1x355k4emcm4g53x'
  },
  {
    id: 2,
    name: 'Salad',
    image_url: 'http://cookdassets.imgix.net/jclb2gadwlkaoy820dkbsg4hp1ov'
  },
  {
    id: 3,
    name: 'Beverages',
    image_url: 'http://cookdassets.imgix.net/uy98wv7hdufxeohjabrujibjnutz'
  },
  {
    id: 4,
    name: 'Starter',
    image_url: 'http://cookdassets.imgix.net/cdd10t498i96ktvozy7j0l0w8r69'
  },
  {
    id: 5,
    name: 'Main Course',
    image_url: 'http://cookdassets.imgix.net/bab7u4pl28s5pbtsv38qo8obgz8d'
  },
  {
    id: 6,
    name: 'Dessert',
    image_url: 'http://cookdassets.imgix.net/84ex65g5ux9gsdy7t4wnx7ply4ko'
  },
  {
    id: 7,
    name: 'Accompaniment',
    image_url: 'http://cookdassets.imgix.net/9bv5jutbrubfn0nhrqvqlivtcdh2'
  },
  {
    id: 8,
    name: 'Snack',
    image_url: 'http://cookdassets.imgix.net/htijnamo7iwyap13tdvl92fc7zbd'
  }
];

const DATA = [
  {
    title: 'Cuisines',
    field: 'cuisines',
    data: CUISINES1
  },
  {
    title: 'Cuisines',
    field: 'cuisines',
    data: CUISINES2
  },
  {
    title: 'Course of Meal',
    field: 'meal_courses',
    data: MEAL_COURSES
  },
  {
    title: 'Meals',
    field: 'meal_times',
    data: MEAL_TIMES
  }
];

export default DATA;
