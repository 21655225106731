import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HomeService } from 'src/services/HomeService';

export const getFeed = createAsyncThunk('home/getFeed', async param => {
  return new HomeService().getFeed(param);
});

const slice = createSlice({
  name: 'home',
  initialState: {
    metadata: {
      total_count: 0,
      page: 0,
      total_page: 0,
      per_page: 0
    },
    data: [],
    status: ''
  },
  extraReducers: {
    [getFeed.pending]: state => {
      state.status = 'pending';
    },
    [getFeed.fulfilled]: (state, action) => {
      state.status = 'success';
      state.metadata = action.payload.metadata;
      state.data = [...state.data, ...action.payload.data];
    },
    [getFeed.rejected]: state => {
      state.status = 'failed';
    }
  },
  reducers: {}
});

export const reducer = slice.reducer;
export const actions = slice.actions;

export default slice;
