import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/devias/layouts/DashboardLayout';
import DocsLayout from 'src/devias/layouts/DocsLayout';
import MainLayout from 'src/devias/layouts/MainLayout';
import HomeView from 'src/devias/views/home/HomeView';
import LoadingScreen from 'src/devias/components/LoadingScreen';
import AuthGuard from 'src/devias/components/AuthGuard';
import GuestGuard from 'src/devias/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/demo/404',
    component: lazy(() => import('src/devias/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/demo/login',
    component: lazy(() => import('src/devias/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/demo/login-unprotected',
    component: lazy(() => import('src/devias/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/demo/register',
    component: lazy(() => import('src/devias/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/demo/register-unprotected',
    component: lazy(() => import('src/devias/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/demo/generic-input',
    component: lazy(() =>
      import('src/devias/views/demo-components/GenericInputView')
    )
  },
  {
    exact: true,
    path: '/demo/ingredients-view',
    component: lazy(() =>
      import('src/devias/views/demo-components/IngredientsView')
    )
  },
  {
    exact: true,
    path: '/demo/products-view',
    component: lazy(() =>
      import('src/devias/views/demo-components/ProductsView')
    )
  },
  {
    exact: true,
    path: '/demo/recipe-tile-view',
    component: lazy(() =>
      import('src/devias/views/demo-components/RecipeTileView')
    )
  },
  {
    path: '/demo/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/demo/app/account',
        component: lazy(() => import('src/devias/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/demo/app/calendar',
        component: lazy(() => import('src/devias/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: ['/demo/app/chat/new', '/demo/app/chat/:threadKey'],
        component: lazy(() => import('src/devias/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/demo/app/chat',
        component: () => <Redirect to="/demo/app/chat/new" />
      },
      {
        exact: true,
        path: '/demo/app/extra/charts/apex',
        component: lazy(() =>
          import('src/devias/views/extra/charts/ApexChartsView')
        )
      },
      {
        exact: true,
        path: '/demo/app/extra/forms/formik',
        component: lazy(() => import('src/devias/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/demo/app/extra/forms/redux',
        component: lazy(() =>
          import('src/devias/views/extra/forms/ReduxFormView')
        )
      },
      {
        exact: true,
        path: '/demo/app/extra/editors/draft-js',
        component: lazy(() =>
          import('src/devias/views/extra/editors/DraftEditorView')
        )
      },
      {
        exact: true,
        path: '/demo/app/extra/editors/quill',
        component: lazy(() =>
          import('src/devias/views/extra/editors/QuillEditorView')
        )
      },
      {
        exact: true,
        path: '/demo/app/kanban',
        component: lazy(() => import('src/devias/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/demo/app/mail/label/:customLabel/:mailId?',
          '/demo/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/devias/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/demo/app/mail',
        component: () => <Redirect to="/demo/app/mail/all" />
      },
      {
        exact: true,
        path: '/demo/app/management/customers',
        component: lazy(() =>
          import('src/devias/views/customer/CustomerListView')
        )
      },
      {
        exact: true,
        path: '/demo/app/management/customers/:customerId',
        component: lazy(() =>
          import('src/devias/views/customer/CustomerDetailsView')
        )
      },
      {
        exact: true,
        path: '/demo/app/management/customers/:customerId/edit',
        component: lazy(() =>
          import('src/devias/views/customer/CustomerEditView')
        )
      },
      {
        exact: true,
        path: '/demo/app/management/invoices',
        component: lazy(() =>
          import('src/devias/views/invoice/InvoiceListView')
        )
      },
      {
        exact: true,
        path: '/demo/app/management/invoices/:invoiceId',
        component: lazy(() =>
          import('src/devias/views/invoice/InvoiceDetailsView')
        )
      },
      {
        exact: true,
        path: '/demo/app/management/orders',
        component: lazy(() => import('src/devias/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/demo/app/management/orders/:orderId',
        component: lazy(() => import('src/devias/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/demo/app/management/products',
        component: lazy(() =>
          import('src/devias/views/product/ProductListView')
        )
      },
      {
        exact: true,
        path: '/demo/app/management/products/create',
        component: lazy(() =>
          import('src/devias/views/product/ProductCreateView')
        )
      },
      {
        exact: true,
        path: '/demo/app/management',
        component: () => <Redirect to="/demo/app/management/customers" />
      },
      {
        exact: true,
        path: '/demo/app/projects/overview',
        component: lazy(() => import('src/devias/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/demo/app/projects/browse',
        component: lazy(() =>
          import('src/devias/views/project/ProjectBrowseView')
        )
      },
      {
        exact: true,
        path: '/demo/app/projects/create',
        component: lazy(() =>
          import('src/devias/views/project/ProjectCreateView')
        )
      },
      {
        exact: true,
        path: '/demo/app/projects/:id',
        component: lazy(() =>
          import('src/devias/views/project/ProjectDetailsView')
        )
      },
      {
        exact: true,
        path: '/demo/app/projects',
        component: () => <Redirect to="/demo/app/projects/browse" />
      },
      {
        exact: true,
        path: '/demo/app/reports/dashboard',
        component: lazy(() => import('src/devias/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/demo/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/devias/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/demo/app/reports',
        component: () => <Redirect to="/demo/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/demo/app/social/feed',
        component: lazy(() => import('src/devias/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/demo/app/social/profile',
        component: lazy(() => import('src/devias/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/demo/app/social',
        component: () => <Redirect to="/demo/app/social/profile" />
      },
      {
        exact: true,
        path: '/demo/app',
        component: () => <Redirect to="/demo/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/demo/404" />
      }
    ]
  },
  {
    path: '/demo/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/demo/docs',
        component: () => <Redirect to="/demo/docs/welcome" />
      },
      {
        exact: true,
        path: '/demo/docs/welcome',
        component: lazy(() => import('src/devias/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/demo/docs/getting-started',
        component: lazy(() =>
          import('src/devias/views/docs/GettingStartedView')
        )
      },
      {
        exact: true,
        path: '/demo/docs/environment-variables',
        component: lazy(() =>
          import('src/devias/views/docs/EnvironmentVariablesView')
        )
      },
      {
        exact: true,
        path: '/demo/docs/deployment',
        component: lazy(() => import('src/devias/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/demo/docs/api-calls',
        component: lazy(() => import('src/devias/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/demo/docs/analytics',
        component: lazy(() => import('src/devias/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/demo/docs/authentication',
        component: lazy(() =>
          import('src/devias/views/docs/AuthenticationView')
        )
      },
      {
        exact: true,
        path: '/demo/docs/routing',
        component: lazy(() => import('src/devias/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/demo/docs/settings',
        component: lazy(() => import('src/devias/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/demo/docs/state-management',
        component: lazy(() =>
          import('src/devias/views/docs/StateManagementView')
        )
      },
      {
        exact: true,
        path: '/demo/docs/theming',
        component: lazy(() => import('src/devias/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/demo/docs/support',
        component: lazy(() => import('src/devias/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/demo/docs/changelog',
        component: lazy(() => import('src/devias/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/demo/404" />
      }
    ]
  },
  {
    path: '/demo',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/demo',
        component: HomeView
      },
      {
        exact: true,
        path: '/demo/pricing',
        component: lazy(() => import('src/devias/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/demo/404" />
      }
    ]
  },
  {
    exact: true,
    path: '/demo',
    component: () => <Redirect to="/demo/app" />
  }
];

export default routes;
