import { BaseService } from './BaseService';

export class PostsService extends BaseService {
  async getAllPosts() {
    return this.get('/posts');
  }

  async getPostById(id) {
    return this.get(`/posts/${id}`);
  }
}
